const listProductsTecnologia = [

    {
        id: 1,
        src: require("@/images/tecnologia/img1.png"),
        name: "Audifonos Inalámbricos",
        code: "LM-TEC001",
        flex: 12,
    },
    {
        id: 2,
        src: require("@/images/tecnologia/img2.png"),
        name: "Set diapositivas con punteros",
        code: "LM-TEC002",
        flex: 12,
    },
    {
        id: 3,
        src: require("@/images/tecnologia/img3.png"),
        name: "USB Pulsera",
        code: "LM-TEC003",
        flex: 12,
    },
    {
        id: 4,
        src: require("@/images/tecnologia/img4.png"),
        name: "Parlante Rectangular",
        code: "LM-TEC004",
        flex: 12,
    },
    {
        id: 5,
        src: require("@/images/tecnologia/img5.png"),
        name: "Balanza Maletas",
        code: "LM-TEC005",
        flex: 12,
    },
    {
        id: 6,
        src: require("@/images/tecnologia/img6.png"),
        name: "Parlante Madera",
        code: "LM-TEC006",
        flex: 12,
    },
    {
        id: 7,
        src: require("@/images/tecnologia/img7.png"),
        name: "Parlante Metal",
        code: "LM-TEC007",
        flex: 12,
    },
    {
        id: 8,
        src: require("@/images/tecnologia/img8.png"),
        name: "USB",
        code: "LM-TEC008",
        flex: 12,
    },
    {
        id: 9,
        src: require("@/images/tecnologia/img9.png"),
        name: "Lapicero USB",
        code: "LM-TEC009",
        flex: 12,
    },
    {
        id: 10,
        src: require("@/images/tecnologia/img10.png"),
        name: "Minero USB",
        code: "LM-TEC010",
        flex: 12,
    },
    {
        id: 11,
        src: require("@/images/tecnologia/img11.jpg"),
        name: "USB Tarjeta",
        code: "LM-TEC011",
        flex: 12,
    },
    {
        id: 12,
        src: require("@/images/tecnologia/img12.png"),
        name: "Auricular modelo teléfono",
        code: "LM-TEC012",
        flex: 12,
    },
    {
        id: 13,
        src: require("@/images/tecnologia/img13.png"),
        name: "Parlante en colores",
        code: "LM-TEC013",
        flex: 12,
    },
    {
        id: 14,
        src: require("@/images/tecnologia/img14.png"),
        name: "Audifonos directos",
        code: "LM-TEC014",
        flex: 12,
    },
    {
        id: 15,
        src: require("@/images/tecnologia/img15.png"),
        name: "Audifonos para colocar en cabeza",
        code: "LM-TEC015",
        flex: 12,
    },
    {
        id: 16,
        src: require("@/images/tecnologia/img16.png"),
        name: "Power Bank Slim",
        code: "LM-TEC016",
        flex: 12,
    },
    {
        id: 17,
        src: require("@/images/tecnologia/img17.png"),
        name: "Camión USB de 8gb",
        code: "LM-TEC017",
        flex: 12,
    },
    {
        id: 18,
        src: require("@/images/tecnologia/img18.png"),
        name: "USB Llave",
        code: "LM-TEC018",
        flex: 12,
    },
    {
        id: 19,
        src: require("@/images/tecnologia/img19.png"),
        name: "USB con impresión",
        code: "LM-TEC019",
        flex: 12,
    },
    {
        id: 20,
        src: require("@/images/tecnologia/img20.png"),
        name: "USB de madera con llavero",
        code: "LM-TEC020",
        flex: 12,
    },

  ]
  
  export { listProductsTecnologia }